import { countries } from "data/countries";
import { states } from "data/states";

export function removeFileExtension(fileName) {
  return fileName.replace(/\.[^/.]+$/, "");
}

export function getFileExtension(fileName) {
  return fileName.match(/\.[0-9a-z]+$/i);
}

export function getFileTypeFromContentType(contentType) {
  return contentType.split("/")[1];
}

export function getFileTypeDisplay(fullContentType) {
  const WORD_CONTENT_TYPES = [
    "msword",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "vnd.ms-word.template.macroEnabled.12",
    "vnd.openxmlformats-officedocument.wordprocessingml.template",
  ];
  const POWERPOINT_CONTENT_TYPES = [
    "vnd.openxmlformats-officedocument.presentationml.template",
    "vnd.ms-powerpoint",
    "vnd.ms-powerpoint.presentation.macroEnabled.12",
    "vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const EXCEL_CONTENT_TYPES = [
    "vnd.ms-excel",
    "vnd.ms-excel.sheet.macroEnabled.12",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const contentType = fullContentType.split("/")[1];

  if (WORD_CONTENT_TYPES.includes(contentType)) {
    return "WORD";
  } else if (POWERPOINT_CONTENT_TYPES.includes(contentType)) {
    return "POWERPOINT";
  } else if (EXCEL_CONTENT_TYPES.includes(contentType)) {
    return "EXCEL";
  } else {
    return contentType.toUpperCase();
  }
}

export const sumArrayValues = (previousValue, currentValue) =>
  previousValue + currentValue;

export function middleEllipsis(
  str,
  lengthCheck = 25,
  startCount = 10,
  endCount = 10
) {
  if (!str) return "";
  if (str.length > lengthCheck) {
    return (
      str.substr(0, startCount) +
      "..." +
      str.substr(str.length - endCount, str.length)
    );
  }
  return str;
}

export function getCountryForCode(countryCode) {
  if (countryCode === "") return null;
  return countries.find((c) => c.value === countryCode);
}

export function getStateForCode(stateCode) {
  return states.find((s) => s.value === stateCode);
}

export function getStatesForCountryCode(countryCode) {
  return states.filter((s) => s.country === countryCode);
}

export function getStreetNumberFromAddress(address) {
  let split = address.split(" ");
  return split[0];
}

export function getStreetNameFromAddress(address) {
  let split = address.split(" ");
  let name = split[1];
  if (!name) return "";
  for (var i = 2; i < split.length; i++) name += " " + split[i];
  return name;
}

export function removeIdFromString(str) {
  return str.replace(/[0-9]+/g, "id");
}

export function pluralize(word, count, suffix = "s") {
  return count > 1 ? `${word}${suffix}` : word;
}

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";
  const k = 1000,
    dm = 0,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatBillingFrequency(frequency) {
  if (frequency === "year") return "YEARLY";
  return "MONTHLY";
}
