import React, { useState } from "react";
import List from "components/shared/list/List";
import styles from "./Member.module.scss";
import { middleEllipsis } from "util/helpers";
import moment from "moment";
import Dropdown from "components/shared/dropdown/Dropdown";
import Icon from "components/shared/icon/Icon";

export default function MemberRow({
  member,
  setShowDeleteModal,
  setDeleteOrgUserEmail,
  setDeleteOrgUserId,
}) {
  function remove() {
    setShowDeleteModal(true);
    setDeleteOrgUserEmail(member.emailId);
    setDeleteOrgUserId(member.orgUserId);
  }

  return (
    <List.Row id={"member-row"}>
      <List.Text
        title={member.emailId}
        subtitle={member.orgUserId}
        width="300px"
      />

      <List.Text
        title={
          member.effectiveDate && moment.unix(member?.effectiveDate).format("l")
        }
        subtitle={member.endDate && moment.unix(member?.endDate).format("l")}
        width="200px"
      />
      <List.Item>
        <div className={styles.hasRegistered}>
          {member.canUserRegister ? (
            <Icon type="check" color="success" />
          ) : (
            <Icon type="close" color="danger" />
          )}
          {member.userCompletedRegistration ? (
            <Icon type="check" color="success" />
          ) : (
            <Icon type="close" color="danger" />
          )}
        </div>
      </List.Item>
      <List.Icon>
        {member.isOrgAdmin && <Icon type="check" color="success" />}
      </List.Icon>
      <List.RowEnd>
        <Dropdown
          id="document-list-item-dropdown-menu-btn"
          isRelativelyPositioned
        >
          <Dropdown.Item
            text="Delete"
            color="danger"
            icon="delete"
            onClick={remove}
            id="dropdown-remove-member-btn"
          />
        </Dropdown>
      </List.RowEnd>
    </List.Row>
  );
}

MemberRow.propTypes = {};
