import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Panel from "components/shared/panel/Panel";
import List from "components/shared/list/List";
import { sortMembers } from "util/sort";
import PageLoader from "components/shared/pageloader/PageLoader";
import MemberRow from "./MemberRow";
import FormField from "components/shared/formfield/FormField";
import NoResults from "components/shared/noresults/NoResults";
import { useDebounce } from "hooks/useDebounce";
import { useOrganizationMembers } from "stores/organizationsStore";
import AddOrganizationMemberModal from "./AddOrganizationMemberModal";
import FloatingActionButton from "components/shared/floatingactionbutton/FloatingActionButton";
import DeleteOrganizationMemberModal from "./DeleteOrganizationMemberModal";

export default function OrganizationMembers({ organization, orgId }) {
  //Data
  const { data: members, error, isLoading } = useOrganizationMembers(orgId);

  //Search
  const [search, setSearch] = useState("");
  const [filteredMembers, setFilteredMembers] = useState();
  const debouncedSearchTerm = useDebounce(search, 300);

  //State
  const [isAddingOrganizationMember, setIsAddingOrganizationMember] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrgUserEmail, setDeleteOrgUserEmail] = useState();
  const [deleteOrgUserId, setDeleteOrgUserId] = useState();

  //Memoization of assets, search term, sort, sort direction and filter
  useMemo(
    () =>
      setFilteredMembers(
        sortMembers(
          members?.data?.filter((member) =>
            member.emailId
              .toLowerCase()
              .replace(/\s/g, "")
              .includes(debouncedSearchTerm.toLowerCase().replace(/\s/g, ""))
          )
        )
      ),
    [members, debouncedSearchTerm]
  );

  //Set our debouncedSearchTerm
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="tile mt-3 mb-4 pb-4">
        <Panel>
          <>
            {members?.data &&
              (!members?.data?.length ? (
                <NoResults size="large" displayBlock>
                  <p>There are no members within this organization</p>
                </NoResults>
              ) : (
                <List>
                  <List.SearchRow>
                    <List.SearchInput>
                      <FormField
                        name="search"
                        type="text"
                        size="small"
                        placeholder="Search"
                        onChange={handleSearchChange}
                        autoComplete="off"
                        hasMargin={false}
                      />
                    </List.SearchInput>
                  </List.SearchRow>
                  {!filteredMembers?.length ? (
                    <NoResults subtitle="Your search did not match any members in this organization. Please try again." />
                  ) : (
                    <>
                      <List.Row id="member-row-header">
                        <List.Text
                          title="Email"
                          subtitle="Org User Id"
                          width="300px"
                        />
                        <List.Text
                          title="Effective Date"
                          subtitle="End Date"
                          width="200px"
                        />
                        <List.Text
                          title="Can Register"
                          subtitle="Has Registered"
                          width="120px"
                        />
                        <List.Text title="Is Org Admin" width="120px" />
                        <List.RowEnd></List.RowEnd>
                      </List.Row>
                      {filteredMembers?.map((member, index) => (
                        <MemberRow
                          member={member}
                          key={member?.emailId}
                          index={index}
                          setShowDeleteModal={setShowDeleteModal}
                          setDeleteOrgUserEmail={setDeleteOrgUserEmail}
                          setDeleteOrgUserId={setDeleteOrgUserId}
                        />
                      ))}
                    </>
                  )}
                </List>
              ))}
            {isLoading && <PageLoader />}
          </>
        </Panel>
      </div>
      <FloatingActionButton>
        <FloatingActionButton.Button
          text="Add Member"
          onClick={() => {
            setIsAddingOrganizationMember(true);
          }}
        />
      </FloatingActionButton>
      {isAddingOrganizationMember && (
        <AddOrganizationMemberModal
          onDone={() => {
            setIsAddingOrganizationMember(false);
          }}
          orgId={orgId}
        />
      )}
      {showDeleteModal && (
        <DeleteOrganizationMemberModal
          onDone={() => {
            setShowDeleteModal(false);
          }}
          email={deleteOrgUserEmail}
          orgUserId={deleteOrgUserId}
        />
      )}
    </>
  );
}

OrganizationMembers.propTypes = {};
