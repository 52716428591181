import React from "react";
import Dropdown from "components/shared/dropdown/Dropdown";
import Card from "components/shared/card/Card";
import { middleEllipsis } from "util/helpers";
import { history } from "util/router";

export default function OrganizationsGrid({ organization, onRemove }) {
  function remove() {
    onRemove();
  }

  return (
    <div className="column is-narrow">
      <Card
        title={middleEllipsis(organization?.displayName, 24)}
        subtitle={organization?.orgId}
        url={`/organizations/${organization?.orgId}`}
        type="organization"
        imageUrl={organization?.brandDetails?.logoUrl}
        id="organization-card"
        showDropdown
        dropdown={
          <Dropdown id="organization-card-dropdown-menu-btn">
            <Dropdown.Item
              text="Edit Details"
              icon="edit"
              onClick={() => {
                history.push(`/organizations/${organization?.orgId}/edit`);
              }}
              id="dropdown-edit-organization-btn"
            />
            <Dropdown.Item
              text="Delete"
              color="danger"
              icon="delete"
              onClick={() => remove(organization)}
              id="dropdown-remove-organization-btn"
            />
          </Dropdown>
        }
      />
    </div>
  );
}
