import React from "react";
import { useRouter } from "util/router";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Organizations from "components/organizations/Organizations";

export function OrganizationsPage({}) {
  const router = useRouter();

  return <Organizations />;
}

export default withAuthenticationRequired(OrganizationsPage, {
  onRedirecting: () => <PageLoader />,
});
