import React, { useEffect, useState, useRef } from "react";
import styles from "./Organization.module.scss";
import Panel from "components/shared/panel/Panel";
import OrganizationDetails from "./details/Details";
import OrganizationDetailsEdit from "./details/Edit";
import { useOrganization } from "stores/organizationsStore";
import PageLoader from "components/shared/pageloader/PageLoader";
import Tabs from "components/shared/tabs/Tabs";
import FloatingActionButton from "components/shared/floatingactionbutton/FloatingActionButton";
import { history } from "util/router";
import { middleEllipsis } from "util/helpers";
import Avatar from "components/shared/avatar/Avatar";
//import OrganizationActivity from "./activity/Activity";
import BackButton from "components/shared/button/BackButton";
import cx from "classnames";
import Dropdown from "components/shared/dropdown/Dropdown";
import OrganizationRemoveModal from "../remove/OrganizationRemoveModal";
import { useOnClickOutside } from "hooks/useOnOutsideClick";
import OrganizationMembers from "./members/OrganizationMembers";
//import Meta from "components/shared/meta/Meta";

export default function Organization({ orgId, section }) {
  const [isRemovingOrganization, setRemovingOrganization] = useState(false);
  const dropdownRef = useRef(null);
  const organizationDropdownRef = useRef(null);

  const { data: organization, error, isLoading } = useOrganization(orgId);

  return (
    <>
      {/* <Meta title={organization?.data?.name || ""} /> */}
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className={styles.organizationContainer}>
          <div className={styles.organization}>
            <BackButton link={`/organizations`} />
            <div className={cx("container", styles.container)}>
              <Panel>
                <article className="tile notification is-white is-justify-content-center mb-0 pb-3">
                  <div className="is-flex is-flex-direction-column has-text-centered is-align-items-center">
                    {organization?.data && (
                      <img
                        className={styles.logo}
                        src={organization?.data?.brandDetails?.logoUrl}
                        alt={organization?.data?.name}
                      />
                    )}
                    <h3
                      className="mb-1 mt-3 has-text-karman100"
                      id="organization-name"
                    >
                      {middleEllipsis(
                        organization?.data?.displayName,
                        50,
                        25,
                        25
                      )}
                    </h3>
                    <p className="pt-0 mt-0 has-text-karman50">
                      {organization?.data?.orgId}
                    </p>
                  </div>
                </article>
                <div className={styles.dropdownMenu}>
                  <span
                    ref={organizationDropdownRef}
                    className="is-hidden"
                  ></span>
                  <Dropdown id="dropdown-organization-menu-btn">
                    {section !== "edit" && (
                      <Dropdown.Item
                        text="Edit Details"
                        icon="edit"
                        onClick={() => {
                          organizationDropdownRef.current.click();
                          history.push(`/organizations/${orgId}/edit`);
                        }}
                        id="dropdown-edit-organization-btn"
                      />
                    )}
                    <Dropdown.Item
                      text="Delete"
                      color="danger"
                      icon="delete"
                      onClick={() => setRemovingOrganization(true)}
                      id="dropdown-remove-organization-btn"
                    />
                  </Dropdown>
                </div>

                <Tabs isCentered>
                  <Tabs.Item
                    isWide
                    url={`/organizations/${orgId}`}
                    text="Details"
                  />
                  <Tabs.Item
                    isWide
                    url={`/organizations/${orgId}/members`}
                    text="Members"
                  />
                </Tabs>
              </Panel>
              {section === undefined && (
                <OrganizationDetails
                  organization={organization?.data}
                  orgId={orgId}
                />
              )}
              {section === "edit" && (
                <OrganizationDetailsEdit
                  organization={organization?.data}
                  orgId={orgId}
                />
              )}
              {section === "members" && (
                <OrganizationMembers
                  organization={organization?.data}
                  orgId={orgId}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {isRemovingOrganization && (
        <OrganizationRemoveModal
          redirectOnSuccess
          organization={organization?.data}
          onDone={() => setRemovingOrganization(false)}
        />
      )}
    </>
  );
}
