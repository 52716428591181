import React from "react";
import PropTypes from "prop-types";
import styles from "./FormFieldRadioButton.module.scss";
import cx from "classnames";

export default function FormFieldRadioButton({
  selected,
  disabled,
  id = "radio-button",
  onChange,
  description,
  name,
  ...inputProps
}) {
  return (
    <label
      className={cx(styles.label, { [styles.labelDisabled]: disabled })}
      {...inputProps}
    >
      <input
        type="radio"
        className={styles.labelInput}
        id={id}
        disabled={disabled}
        onChange={onChange}
        name={name}
      />
      <div
        className={cx(styles.labelCircle, {
          [styles.labelCircleSelected]: selected,
          [styles.labelCircleDisabled]: disabled,
        })}
      >
        <div
          className={cx(styles.labelRadio, {
            [styles.labelRadioSelected]: selected,
            [styles.labelRadioDisabled]: disabled,
          })}
        ></div>
      </div>
      {description}
    </label>
  );
}

FormFieldRadioButton.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};
