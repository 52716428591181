import React from "react";
import PropTypes from "prop-types";
import styles from "./List.module.scss";
import cx from "classnames";

export default function List({
  children,
  hideLastRowBorder,
  addListBorderBottom,
  showFirstRowBorder,
  ...otherProps
}) {
  return (
    <div
      className={cx("column is-full", styles.rowContainer, {
        [styles.hideLastRowBorder]: hideLastRowBorder,
        [styles.addListBorderBottom]: addListBorderBottom,
        [styles.showFirstRowBorder]: showFirstRowBorder,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
}

function Row({
  children,
  rounded = false,
  isClickable = true,
  isActive,
  hasLeftBorder,
  hideBorderBottom,
  hasBoxShadow,
  ...otherProps
}) {
  return (
    <div
      className={cx(
        {
          [styles.rowRounded]: rounded,
          [styles.rowClickable]: isClickable,
          [styles.rowLeftBorder]: hasLeftBorder,
          [styles.rowActive]: isActive,
          [styles.hideBorderBottom]: hideBorderBottom,
          [styles.hasBoxShadow]: hasBoxShadow,
        },
        styles.row
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

function Item({ align, children, ...otherProps }) {
  return <>{children}</>;
}

function RowEnd({ children, ...otherProps }) {
  return (
    <div className={styles.rowEnd} {...otherProps}>
      {children}
    </div>
  );
}

function Icon({ children }) {
  return <div className={cx(styles.text)}>{children}</div>;
}

function Text({ title, subtitle, hasBlueSubtitle, width }) {
  return (
    <div
      className={cx(styles.text, { [styles.hasWidth]: width })}
      style={{ width: width }}
    >
      <div className={styles.title}>{title}</div>
      <div
        className={cx(styles.subtitle, {
          [styles.hasBlueSubtitle]: hasBlueSubtitle,
        })}
      >
        {subtitle}
      </div>
    </div>
  );
}

function SearchRow({
  children,
  rounded = false,
  isActive,
  hasLeftBorder,
  hideBottomBorder,
  isSticky = false,
  paddingSize = "xlarge",
  spaceEvenly,
  ...otherProps
}) {
  return (
    <div
      className={cx(
        {
          [styles.rowRounded]: rounded,
          [styles.rowLeftBorder]: hasLeftBorder,
          [styles.rowHideBottomBorder]: hideBottomBorder,
          [styles.rowActive]: isActive,
          [styles.rowSticky]: isSticky,
          [styles.rowSpaceEvenly]: spaceEvenly,
        },
        styles.row,
        styles.rowSearch,
        styles[paddingSize]
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

function SearchInput({ children }) {
  return <div className={styles.searchInput}>{children}</div>;
}

function SearchFilters({ children }) {
  return <div className={styles.searchFilters}>{children}</div>;
}

List.Row = Row;
List.Item = Item;
List.RowEnd = RowEnd;
List.Text = Text;
List.SearchRow = SearchRow;
List.SearchInput = SearchInput;
List.SearchFilters = SearchFilters;
List.Icon = Icon;

List.propTypes = {
  /**
   * The list of rows to display in the main body section of the Modal
   */
  children: PropTypes.node,
};

List.Row.propTypes = {
  /**
   * The function that should fire upon clicking the row
   */
  onClick: PropTypes.func,
};

List.Item.propTypes = {};

List.RowEnd.propTypes = {};

List.SearchRow.propTypes = {
  /**
   * A Child of the List.Search component should be a FormField
   */
  children: PropTypes.node,
};
