import React, { useState, useEffect, useMemo } from "react";
import FloatingActionButton from "components/shared/floatingactionbutton/FloatingActionButton";
import NoContent from "components/shared/nocontent/NoContent";
import { sortOrganizations } from "util/sort";
import PageLoader from "components/shared/pageloader/PageLoader";
import OrganizationAddModal from "./organization/add/AddModal";
import OrganizationsGrid from "components/organizations/layout/Grid";
import OrganizationRemoveModal from "./remove/OrganizationRemoveModal";
import { useOrganizations } from "stores/organizationsStore";
import { useDebounce } from "hooks/useDebounce";
import List from "components/shared/list/List";
import FormField from "components/shared/formfield/FormField";
import FormFieldRadioButton from "components/shared/form/radio/FormFieldRadioButton";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";
import Filter from "components/shared/filter/Filter";

const OrgStatusFilterOptions = {
  ALL: {
    id: "ALL",
    name: "All Orgs",
    filterFunction: (org) => {
      return org;
    },
  },
  PRISIDIO_BRANDED: {
    id: "PRISIDIO_BRANDED",
    name: "Is Prisidio Branded",
    filterFunction: (org) => {
      return !!org?.isPrisidioBranded;
    },
  },
  ORG_BRANDED: {
    id: "ORG_BRANDED",
    name: "Is Org Branded",
    filterFunction: (org) => {
      return !org?.isPrisidioBranded;
    },
  },
};

export default function Organizations() {
  const [isAddingOrganization, setIsAddingOrganization] = useState(false);
  const [initialOrganizationName, setInitialOrganizationName] = useState("");
  const [isRemovingOrganization, setIsRemovingOrganization] = useState(false);
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const [showTestOrgs, setShowTestOrgs] = useState(false);
  const [showPrisidioBranded, setShowPrisidioBranded] = useState(false);
  const [activeOrgFilter, setActiveOrgFilter] = useState(
    OrgStatusFilterOptions.ALL
  );
  const [activeFilter, setActiveFilter] = useState();

  const { data: organizations, error, isLoading, isIdle } = useOrganizations();

  const filteredOrganizations = useMemo(
    () =>
      sortOrganizations(
        organizations?.data
          ?.filter((org) =>
            org?.displayName
              ?.toLowerCase()
              .replace(/\s/g, "")
              .includes(debouncedSearchTerm.toLowerCase().replace(/\s/g, ""))
          )
          ?.filter((org) => displayTestOrg(org))
          ?.filter(activeOrgFilter.filterFunction)
      ),
    [
      organizations,
      debouncedSearchTerm,
      showTestOrgs,
      showPrisidioBranded,
      activeOrgFilter,
    ]
  );

  //Set our debouncedSearchTerm
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  function displayTestOrg(org) {
    if (showTestOrgs) return true;

    if (
      !showTestOrgs &&
      (org?.displayName?.toLowerCase().includes("swoosh") ||
        org?.displayName?.toLowerCase().includes("a public display name") ||
        org?.displayName
          ?.toLowerCase()
          .includes("temp asset svc client test organization"))
    ) {
      return false;
    }

    return true;
  }

  function onFilterClick(ref, isActive) {
    if (isActive) {
      setActiveFilter(ref);
    } else {
      setActiveFilter(null);
    }
  }

  function removeOrganization(organization) {
    setIsRemovingOrganization(true);
    setActiveOrganization(organization);
  }

  function renderOrganizationsContent() {
    return isLoading || isIdle ? (
      <PageLoader />
    ) : organizations?.data && organizations?.data?.length ? (
      <div className="container">
        <div className="columns is-multiline mt-2">
          {filteredOrganizations.map((organization, index) => (
            <OrganizationsGrid
              organization={organization}
              onRemove={() => removeOrganization(organization)}
              key={organization.orgId}
              assetLength={organizations?.data?.length}
            />
          ))}
        </div>
      </div>
    ) : (
      <div className="container">
        <NoContent
          title="No Organizations"
          subtitle="Please use the Add button to create an organization in this environment."
        />
      </div>
    );
  }

  if (error)
    return (
      <NoContent
        title="Uhoh."
        subtitle="There was an error retreiving orgs.  Please try again"
      />
    );

  return (
    <>
      <FloatingActionButton>
        <FloatingActionButton.Button
          //parentRef={addOrganizationsRef}
          text="Add"
          onClick={() => {
            setIsAddingOrganization(true);
          }}
        />
      </FloatingActionButton>

      <section>
        <div className="container mt-2">
          <List>
            <List.SearchRow paddingSize="large" rounded>
              <List.SearchInput>
                <FormField
                  name="search"
                  type="text"
                  size="small"
                  placeholder="Search"
                  onChange={handleSearchChange}
                  autoComplete="off"
                  hasMargin={false}
                />
              </List.SearchInput>
              <>
                <Filter
                  title={activeOrgFilter.name}
                  filterRef="filterOptions"
                  isRight
                  onClick={onFilterClick}
                  active={activeFilter === "filterOptions"}
                >
                  <Filter.HeaderItem title="Filter by Org Branding" />
                  <Filter.SortItem
                    name={OrgStatusFilterOptions.ALL.name}
                    onClick={() => {
                      setActiveOrgFilter(OrgStatusFilterOptions.ALL);
                    }}
                    selected={
                      activeOrgFilter?.id === OrgStatusFilterOptions.ALL.id
                    }
                  />
                  <Filter.SortItem
                    name={OrgStatusFilterOptions.PRISIDIO_BRANDED.name}
                    onClick={() => {
                      setActiveOrgFilter(
                        OrgStatusFilterOptions.PRISIDIO_BRANDED
                      );
                    }}
                    selected={
                      activeOrgFilter?.id ===
                      OrgStatusFilterOptions.PRISIDIO_BRANDED.id
                    }
                  />
                  <Filter.SortItem
                    name={OrgStatusFilterOptions.ORG_BRANDED.name}
                    onClick={() => {
                      setActiveOrgFilter(OrgStatusFilterOptions.ORG_BRANDED);
                    }}
                    selected={
                      activeOrgFilter?.id ===
                      OrgStatusFilterOptions.ORG_BRANDED.id
                    }
                  />
                </Filter>
              </>
              <List.RowEnd>
                <FormFieldCheckbox
                  checked={showTestOrgs}
                  onChange={() => setShowTestOrgs(!showTestOrgs)}
                  id="show-test-orgs-checkbox"
                  label="Show Test Orgs"
                />
              </List.RowEnd>
            </List.SearchRow>
          </List>
        </div>
        {renderOrganizationsContent()}
      </section>
      {isAddingOrganization && (
        <OrganizationAddModal
          onDone={() => {
            setIsAddingOrganization(false);
            setInitialOrganizationName("");
          }}
          initialName={initialOrganizationName}
        />
      )}
      {isRemovingOrganization && (
        <OrganizationRemoveModal
          organization={activeOrganization}
          onDone={() => setIsRemovingOrganization(false)}
        />
      )}
    </>
  );
}

Organizations.propTypes = {};
