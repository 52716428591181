import { get, add, update, del } from "./serviceConfig";
const API_BASE_URL = process.env.REACT_APP_ADMIN_SVC_API_URL;
//const API_BASE_URL = process.env.REACT_APP_ORGANIZATIONS_SVC_API_URL;

export const organizationsService = {
  //Organizations
  getOrganizations() {
    return get(`${API_BASE_URL}/admin/v1/organizations`);
  },

  //Organization
  addOrganization(data) {
    return add(`${API_BASE_URL}/admin/v1/organizations`, {}, data);
  },

  getOrganization(orgId) {
    return get(`${API_BASE_URL}/admin/v1/organizations/${orgId}`);
  },

  updateOrganization(orgId, data) {
    return update(`${API_BASE_URL}/admin/v1/organizations/${orgId}`, {}, data);
  },

  deleteOrganization(orgId) {
    return del(`${API_BASE_URL}/admin/v1/organizations/${orgId}`);
  },

  //Members
  getOrganizationMembers(orgId) {
    return get(`${API_BASE_URL}/admin/v1/organizations/${orgId}/users`);
  },

  addOrganizationMember(data, sendEmail) {
    return add(
      `${API_BASE_URL}/admin/v1/organizationUsers${
        !!sendEmail ? `?sendEmail=${sendEmail}` : ""
      }`,
      {},
      data
    );
  },
  updateOrganizationMember(orgUserId, data) {
    return update(
      `${API_BASE_URL}/admin/v1/organizationUsers/${orgUserId}`,
      {},
      data
    );
  },

  deleteOrganizationMember(orgUserId) {
    return del(`${API_BASE_URL}/admin/v1/organizationUsers/${orgUserId}`);
  },
};
