import React from "react";
import PropTypes from "prop-types";
import { history } from "util/router";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import { addOrganizationMember } from "stores/organizationsStore";
import AttributeValueInputs from "./AttributeValueInputs";
import Message from "components/shared/toast/Message";
import { middleEllipsis } from "util/helpers";
import moment from "moment";
import { errorHandler } from "util/errors";

function AddOrganizationMemberModal({ orgId, onDone }) {
  const methods = useForm();

  const { mutate, isLoading } = useMutation(
    (data) => {
      return addOrganizationMember(data, data.sendEmail);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success
            text={`${middleEllipsis(response.data.emailId)} has been added.`}
          />
        );
        onDone();
      },
      onError: (error) => {
        toast.error(errorHandler(`${error.response.data?.errorCode}`));
      },
    }
  );

  function onSubmit(data) {
    let member = {
      orgId: orgId,
      emailId: data.email,
      effectiveDate: moment(data?.effectiveDate).unix().toString(),
      endDate: data?.endDate ? moment(data?.endDate).unix().toString() : null,
      registrationEffectiveDate: moment(data?.registrationEffectiveDate)
        .unix()
        .toString(),
      registrationEndDate: data?.registrationEndDate
        ? moment(data?.registrationEndDate).unix().toString()
        : null,
      isOrgAdmin: data.isOrgAdmin,
      sendEmail: data.sendEmail,
    };

    mutate(member);
  }

  return (
    <Modal onClose={onDone}>
      <Modal.Header title="Add Organization Member" onClose={onDone} />
      <Modal.Body hasScroll>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <AttributeValueInputs />
          </form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer
        onCancel={onDone}
        onSubmit={methods.handleSubmit(onSubmit)}
        submitText="Save"
        isSubmitting={isLoading}
      />
    </Modal>
  );
}

AddOrganizationMemberModal.propTypes = {};

export default AddOrganizationMemberModal;
