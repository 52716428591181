import axios from "axios";
//TODO - These API BASE URLs will need to be fed in depending on resource endpoint
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function get(api, params = {}) {
  return await axios.get(api, {
    params: params,
  });
}

export async function add(api, params = {}, payload) {
  return await axios.post(api, payload);
}

export async function addWithCredentials(api, payload) {
  return await axios.post(api, payload, {
    params: {
      withCredentials: true,
    },
  });
}

export async function upload(api, data, onUploadProgress) {
  return await axios.post(api, data, onUploadProgress);
}

export async function update(api, params = {}, payload) {
  return await axios.put(api, payload);
}

export async function del(api, params = {}) {
  return await axios.delete(api);
}

export async function downloadWithoutWindow(api) {
  return await axios.post(api, null, { withCredentials: true });
}

export async function download(api) {
  //Post to API to get cookie and short lived session
  return await axios
    .post(api, null, { withCredentials: true })
    .then((response) => {
      //Use original API as a GET to trigger content-disposition from browser to download
      window.location.href = api;
    });
}

export async function getImage(api, params = {}) {
  return await axios
    .get(api, {
      params: params,
    })
    .then((response) => {
      return get(`${response?.data?.single?.downloadUrl}?base64=true`);
    });
}

export async function getMediaImage(api, useOriginal, useBase64) {
  return get(`${api}?original=${useOriginal}&base64=${useBase64}`);
}
