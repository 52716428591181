import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardLinks from "./DashboardLinks";
import DashboardLoggedInUserInfo from "./DashboardLoggedInUserInfo";

const links = [
  {
    name: "Auth0",
    description: "User / Identity admin",
    url: "https://manage.auth0.com/",
  },
  {
    name: "Hubspot",
    description: "Marketing website admin",
    url: "https://app.hubspot.com/login",
  },
  {
    name: "Stripe",
    description: "Payment admin",
    url: "https://dashboard.stripe.com/login",
  },
  {
    name: "Outlook Bookings Calendars",
    description: "Webinar bookings",
    url: "https://outlook.office.com/bookings/calendar",
  },

  {
    name: "Prisidio Help Center",
    description: "Prisidio User Help",
    url: "http://help.prisid.io/",
  },
  {
    name: "Confluence",
    description: "Internal Documentation",
    url: "https://prisidio.atlassian.net/wiki/home",
  },
  {
    name: "JIRA",
    description: "Dev Work / Tracking",
    url: "https://prisidio.atlassian.net/jira/projects",
  },
  {
    name: "Livestorm",
    description: "Webinar Tool",
    url: "https://app.livestorm.co/",
  },
  {
    name: "Grafana",
    description: "API Logs (Requires Permission)",
    url: "https://g-0bc2dc02c4.grafana-workspace.us-east-1.amazonaws.com/",
  },
  {
    name: "Production Logs",
    description: "Prod Logs (Requires Permission)",
    url: "https://prod-logs.prisidio.com/_dashboards/app/discover#/",
  },
];

const slack = [
  {
    name: "#somethings-wrong",
    description: "Post here if you run into a bug",
    url: "https://prisidio.slack.com/archives/C026X2BMEJ3",
  },
  {
    name: "#dev",
    description: "Main Dev Channel",
    url: "https://prisidio.slack.com/archives/C01SLSE45S9",
  },
  {
    name: "#911",
    description: "P1 Emergency production issue channel",
    url: "https://prisidio.slack.com/archives/C044JN61V1A",
  },
  {
    name: "#notifications-major",
    description: "Automated reporting - major errors",
    url: "https://prisidio.slack.com/archives/C02D0NJTF5W",
  },
  {
    name: "#notifications-minor",
    description: "Automated reporting - minor errors",
    url: "https://prisidio.slack.com/archives/C01V9V1P6SC",
  },

  {
    name: "#releases",
    description: "Upcoming production release chat",
    url: "https://prisidio.slack.com/archives/C02UJUJNFQS",
  },
  {
    name: "#innovation",
    description: "App Improvement Ideas",
    url: "https://prisidio.slack.com/archives/C044NF43TBJ",
  },
];

export default function Dashboard({}) {
  const { user } = useAuth0();

  return (
    <div className="container">
      <p className="mt-4 mb-4 title">{user && <>Hi, {user?.given_name}.</>}</p>
      <div className={`columns`}>
        <div className="column is-one-third">
          <DashboardLinks title="Useful Links" links={links} />
        </div>
        <div className="column is-one-third">
          <DashboardLinks title="Slack Channels" links={slack} />
        </div>
        <div className="column is-one-third">
          <DashboardLoggedInUserInfo />
        </div>
      </div>
    </div>
  );
}
