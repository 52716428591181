import React from "react";
import styles from "./FormFieldCheckbox.module.scss";

export default function FormFieldCheckbox({
  error,
  inputRef,
  disabled,
  checked,
  id,
  label,
  ...inputProps
}) {
  return (
    <div className={styles.formFieldCheckboxContainer}>
      <input
        className={styles.formFieldCheckbox}
        disabled={disabled}
        checked={checked}
        ref={inputRef}
        type="checkbox"
        id={id}
        {...inputProps}
      />
      <label
        className={styles.formFieldCheckboxLabel}
        htmlFor={id}
        id={`${id}-label`}
      >
        {label}
      </label>
    </div>
  );
}
