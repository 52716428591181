import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import PageLoader from "components/shared/pageloader/PageLoader";
import Dashboard from "components/dashboard/Dashboard";

function Home() {
  return <Dashboard />;
}

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <PageLoader />,
});
