import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Modal from "components/shared/modal/Modal";
import { deleteOrganizationMember } from "stores/organizationsStore";
import Message from "components/shared/toast/Message";
import { middleEllipsis } from "util/helpers";

export default function DeleteOrganizationMemberModal({
  email,
  orgUserId,
  onDone,
}) {
  const { mutate, isLoading } = useMutation(
    () => {
      return deleteOrganizationMember(orgUserId);
    },
    {
      onSuccess: async (response) => {
        toast.success(<Message.Success text={`${email} has been removed.`} />);
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  return (
    <Modal onClose={onDone}>
      <Modal.Header title="Delete Org Member" onClose={onDone} />
      <Modal.Body>
        <p>
          Are you sure you want to delete <strong>{email}</strong> from the
          organization?
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={onDone}
        onSubmit={onSubmit}
        isSubmitting={isLoading}
        submitColor="danger"
        submitText="Delete"
      />
    </Modal>
  );
}
