import React, { useEffect, useState } from "react";
import FormField from "components/shared/formfield/FormField";
import { useFormContext } from "react-hook-form";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";

export default function AttributeValueInputs({}) {
  const { register, control, errors, setValue } = useFormContext();

  // {
  //   "orgId": "string",
  //   "emailId": "string",
  //   "registrationEffectiveDate": "string",
  //   "registrationEndDate": "string",
  //   "userCompletedRegistration": true,
  //   "effectiveDate": "string",
  //   "endDate": "string"
  //   "isOrgAdmin": true,
  //   "sendEmail": true
  // }

  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [hasSendEmail, setHasSendEmail] = useState(false);

  function handleOrgAdminClick() {
    setIsOrgAdmin(!isOrgAdmin);
  }

  function handleSendEmailClick() {
    setHasSendEmail(!hasSendEmail);
  }

  return (
    <>
      <FormField
        autoFocus
        name="email"
        type="email"
        defaultValue={null}
        placeholder="Email Address"
        size="normal"
        id="person-invite-email"
        label="Email Address"
        error={errors.email}
        lpIgnore
        inputRef={register({
          required: "Please enter a valid email",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Please enter a valid email format",
          },
        })}
      />
      <FormField
        name="effectiveDate"
        type="date"
        label="Effective Date"
        placeholder=""
        size="normal"
        id="organization-member-add-effective-date"
        defaultValue={null}
        error={errors.effectiveDate}
        lpIgnore
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="endDate"
        type="date"
        label="End Date"
        placeholder=""
        size="normal"
        id="organization-member-add-end-date"
        defaultValue={null}
        description="How long is this user a member of this organization.  Leave blank for infinite."
        error={errors.endDate}
        lpIgnore
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="registrationEffectiveDate"
        type="date"
        label="Registration Effective Date"
        placeholder=""
        size="normal"
        id="organization-member-add-registration-effective-date"
        defaultValue={null}
        description="REQUIRED. When this user can register for this organization."
        error={errors.registrationEffectiveDate}
        lpIgnore
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="registrationEndDate"
        type="date"
        label="Registration End Date"
        placeholder=""
        size="normal"
        id="organization-member-add-registration-end-date"
        defaultValue={null}
        description="What is the last day this user can register for this organization. Leave blank for infinite"
        error={errors.registrationEndDate}
        lpIgnore
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <div className="is-flex">
        <FormFieldCheckbox
          name="isOrgAdmin"
          label="Is Org Admin"
          id="organization-member-add-is-org-admin"
          description="Is this user an organization admin?"
          checked={isOrgAdmin}
          onChange={handleOrgAdminClick}
          error={errors.isOrgAdmin}
          lpIgnore
          inputRef={register}
        />
      </div>

      <div className="is-flex mt-2">
        <FormFieldCheckbox
          name="sendEmail"
          label="Send Invite Email"
          id="organization-member-add-send-email"
          description="Send invite email to new org member?"
          checked={hasSendEmail}
          onChange={handleSendEmailClick}
          error={errors.sendEmail}
          lpIgnore
          inputRef={register}
        />
      </div>
    </>
  );
}
