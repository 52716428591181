import React from "react";
import { useRouter } from "util/router";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Organization from "components/organizations/organization/Organization";

export function OrganizationPage({}) {
  const router = useRouter();

  return (
    <Organization orgId={router.query.orgId} section={router.query.section} />
  );
}

export default withAuthenticationRequired(OrganizationPage, {
  onRedirecting: () => <PageLoader />,
});
