import React from "react";
import cx from "classnames";
import styles from "./FormFieldSelect.module.scss";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={styles.multilineOption}>
        <span className={styles.multilineLabel}>{props.label}</span>
        <span className={styles.multilineDescription}>
          {props.data?.description}
        </span>
      </div>
    </components.Option>
  );
};

export default function FormFieldSelect(props) {
  const {
    error,
    type,
    inputRef,
    options,
    id,
    menuPlacement,
    onChange,
    isDisabled,
    hasMultilineOptions,
    required,
    control,
    setValue,
    ...inputProps
  } = props;

  function handleChange(newValue) {
    if (setValue) setValue(inputProps.name, newValue, { shouldDirty: true });
    if (onChange) onChange(newValue);
  }

  return (
    <>
      <div className={cx("field", styles.field)}>
        {props.label && (
          <label className="label has-text-size-normal" htmlFor={id}>
            {props.label}
          </label>
        )}

        <Controller
          {...inputProps}
          name={inputProps.name}
          rules={{ required }}
          control={control}
          render={({ value }) => (
            <Select
              id={id}
              instanceId={id}
              className={cx(styles.select, { [styles.isDanger]: error })}
              classNamePrefix={styles.select}
              components={{ Option }}
              options={options}
              defaultValue={inputProps.defaultValue}
              value={value}
              menuPlacement={menuPlacement}
              isDisabled={isDisabled}
              placeholder={inputProps.placeholder}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          )}
        />
        {props.description && (
          <span className="input-description">{props.description}</span>
        )}

        {error && <p className="help is-danger">{error.message}</p>}
      </div>
    </>
  );
}
