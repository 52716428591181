import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Modal from "components/shared/modal/Modal";
import { deleteOrganization } from "stores/organizationsStore";
import Message from "components/shared/toast/Message";
import { middleEllipsis } from "util/helpers";
import { history } from "util/router";

export default function OrganizationRemoveModal({
  organization,
  onDone,
  redirectOnSuccess,
}) {
  const { mutate, isLoading } = useMutation(
    () => {
      return deleteOrganization(organization?.orgId);
    },
    {
      onSuccess: async (response) => {
        toast.success(
          <Message.Success
            text={`${middleEllipsis(
              organization.name,
              35,
              15,
              15
            )} has been removed.`}
          />
        );
        if (redirectOnSuccess) {
          history.push(`/organizations`);
        }
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  return (
    <Modal onClose={() => onDone()}>
      <Modal.Header title="Delete Organization" onClose={() => onDone()} />
      <Modal.Body>
        <p>
          Are you sure you want to delete{" "}
          <strong>{`${middleEllipsis(organization?.name, 24)}`}</strong>. This
          action cannot be undone.
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onDone()}
        onSubmit={() => onSubmit()}
        isSubmitting={isLoading}
        submitText="Delete"
        submitColor="danger"
      />
    </Modal>
  );
}
