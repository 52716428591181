import billToValues from "enums/billTo";

export const billTo = [
  {
    label: "-- Select an option --",
    value: "",
  },
  {
    label: "Customer",
    value: billToValues.CUSTOMER,
    key: billToValues.CUSTOMER,
  },
  {
    label: "Organization",
    value: billToValues.ORGANIZATION,
    key: billToValues.ORGANIZATION,
  },
  {
    label: "Partner",
    value: billToValues.PARTNER_MANAGED_BILLING,
    key: billToValues.PARTNER_MANAGED_BILLING,
  },
];
