import { ErrorCodes } from "enums/errors";

export function errorHandler(response) {
  if (response.includes("NOT_VALID_USER"))
    return "There was an error registering this user. Please try again.";
  if (response.includes("undefined"))
    return "There was an error. Please try again later.";
  if (response.includes("PERSON_ALREADY_EXISTS"))
    return "A person with that email address already exists in this vault.";
  if (response.includes("INVALID_WEBSITE_URL")) return "Invalid website URL.";
  if (response.includes("INVALID_TITLE"))
    return "Document Name should be less than 50 characters";
  if (response.includes(ErrorCodes.EMAILHASBEENUSED))
    return "A user with this email address already exists.";
  return "There was an error. Please try again later.";
}
