import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { cacheKeys, sharedQueryClient } from "stores/storeConfig";
import { organizationsService } from "services/organizationsService";

const fiveMinutesInMs = 1000 * 60 * 5;
const queryClient = sharedQueryClient;
const organizationsCacheKey = cacheKeys.ORGANIZATIONS;
const organizationCacheKey = cacheKeys.ORGANIZATION;
const organizationMembersCacheKey = cacheKeys.ORGANIZATIONMEMBERS;

// Fetch all organizations (hook)
// NOTE: This uses the assetsService, not organizationsService
export function useOrganizations() {
  const cacheKey = [organizationsCacheKey];
  const query = () => organizationsService.getOrganizations();
  return useQuery(cacheKey, query, {
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage}`);
    },
  });
}

// Fetch organization data (hook)
export function useOrganization(orgId) {
  const cacheKey = [organizationCacheKey, { orgId }];
  const query = () => organizationsService.getOrganization(orgId);
  return useQuery(cacheKey, query, {
    enabled: !!orgId,
    staleTime: fiveMinutesInMs,
  });
}

// Add organization
export async function addOrganization(data) {
  const response = await organizationsService.addOrganization(data);
  await queryClient.invalidateQueries([organizationsCacheKey]);
  return response;
}

// Update an organization
export async function updateOrganization(orgId, data) {
  const response = await organizationsService.updateOrganization(orgId, data);
  await Promise.all([
    queryClient.invalidateQueries([organizationCacheKey, { orgId }]),
    queryClient.invalidateQueries([organizationsCacheKey]),
  ]);
  return response;
}

// Delete an organization
export async function deleteOrganization(orgId) {
  const response = await organizationsService.deleteOrganization(orgId);
  await Promise.all([
    queryClient.invalidateQueries(organizationsCacheKey),
    queryClient.removeQueries([organizationCacheKey, { orgId }]),
  ]);
  return response;
}

//MEMBERS

// Fetch organization members data
export function useOrganizationMembers(orgId) {
  const cacheKey = [organizationMembersCacheKey, { orgId }];
  const query = () => organizationsService.getOrganizationMembers(orgId);
  return useQuery(cacheKey, query, {
    enabled: !!orgId,
    staleTime: fiveMinutesInMs,
  });
}

// Add organization member
export async function addOrganizationMember(data, sendEmail) {
  const response = await organizationsService.addOrganizationMember(
    data,
    sendEmail
  );
  await Promise.all([
    queryClient.invalidateQueries(organizationsCacheKey),
    queryClient.invalidateQueries(organizationMembersCacheKey),
  ]);
  return response;
}

// Update an organization member
export async function updateOrganizationMember(orgUserId, data) {
  const response = await organizationsService.updateOrganizationMember(
    orgUserId,
    data
  );
  await Promise.all([
    queryClient.invalidateQueries(organizationsCacheKey),
    queryClient.invalidateQueries(organizationMembersCacheKey),
  ]);
  return response;
}

// Delete an organization member
export async function deleteOrganizationMember(orgUserId) {
  const response = await organizationsService.deleteOrganizationMember(
    orgUserId
  );
  await Promise.all([
    queryClient.invalidateQueries(organizationsCacheKey),
    queryClient.invalidateQueries(organizationMembersCacheKey),
  ]);
  return response;
}
