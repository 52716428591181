import React, { Component } from "react";
import styles from "./Panel.module.scss";
import cx from "classnames";
import { VaultStatusEnum } from "enums/vaultStatus";

export default function Panel({ hasPadding, children, backgroundImg, status }) {
  return (
    <div
      className={cx([styles.panel], {
        "pl-3 pr-3 pt-3 pb-0": hasPadding,
        [styles.backgroundImg]: backgroundImg,
        [styles.danger]: status === VaultStatusEnum.DANGER,
        [styles.warning]: status === VaultStatusEnum.WARNING,
      })}
      style={
        backgroundImg && {
          backgroundImage: `url(${backgroundImg})`,
        }
      }
    >
      {children}
    </div>
  );
}

function Title({ text, hasBorderBottom = true, subtitle }) {
  return (
    <>
      <h2
        className={cx("has-text-left", styles.title, {
          [styles.hasBorderBottom]: hasBorderBottom,
          "pb-3": !subtitle,
        })}
      >
        {text}
      </h2>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </>
  );
}

function Footer({ children }) {
  return <div className={styles.footer}>{children}</div>;
}

Panel.Title = Title;
Panel.Footer = Footer;
