import planType from "../enums/planType";

export default [
  {
    label: "-- Select an option --",
    value: "",
  },
  {
    label: "Unlimited",
    value: planType.UNLIMITED_VAULT,
    key: planType.UNLIMITED_VAULT,
  },
  {
    label: "Essentials",
    value: planType.ESSENTIALS_VAULT,
    key: planType.ESSENTIALS_VAULT,
  },
  {
    label: "AARP Unlimited",
    value: planType.AARP_UNLIMITED_VAULT,
    key: planType.AARP_UNLIMITED_VAULT,
  },
  {
    label: "AARP Essentials",
    value: planType.AARP_ESSENTIALS_VAULT,
    key: planType.AARP_ESSENTIALS_VAULT,
  },
  {
    label: "Test Org Unlimited",
    value: planType.TESTORG_UNLIMITED_VAULT,
    key: planType.TESTORG_UNLIMITED_VAULT,
  },
  {
    label: "Test Org Essentials",
    value: planType.TESTORG_ESSENTIALS_VAULT,
    key: planType.TESTORG_ESSENTIALS_VAULT,
  },
];
