import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import styles from "./Card.module.scss";
import Avatar from "components/shared/avatar/Avatar";
import { history } from "util/router";
import CircleIcon from "../icon/CircleIcon";
import cx from "classnames";
import FormFieldRadioButton from "../form/radio/FormFieldRadioButton";

export default function Card({
  icon,
  title,
  subtitle,
  splitSubtitle,
  description,
  selected,
  clickable,
  type = "document",
  url,
  imageUrl,
  isLoading,
  dropdown,
  name,
  status,
  role,
  showRadioButton,
  showDropdown,
  showStatus,
  assetId,
  helpMenuRef,
  ...otherProps
}) {
  function DocumentCard() {
    return (
      <div
        onClick={(e) => {
          history.push(url);
        }}
        className={cx(`card ${styles.cardDoc}`)}
        {...otherProps}
      >
        {type === "folder" ? (
          <Icon type="folder" size="lg" />
        ) : (
          <div
            className={cx(`card-image ${styles.cardImage}`, {
              [styles.cardAvatar]: !imageUrl,
            })}
          >
            <figure className="image">
              {imageUrl ? (
                <img src={imageUrl} alt={title} />
              ) : (
                <CircleIcon icon="insert_drive_file" size="xxlarge" />
              )}
            </figure>
          </div>
        )}
        {dropdown}
        <footer className={`card-footer ${styles.cardFooter}`}>
          <span className={styles.cardTitle}>{title}</span>
        </footer>
      </div>
    );
  }

  function SimpleCard() {
    return (
      <div
        className={cx("card", styles.cardSimple, {
          [styles.clickable]: clickable,
          [styles.selected]: selected,
        })}
        {...otherProps}
      >
        <div className={styles.cardSimpleContent}>
          <h3>{title}</h3>
          <span className={styles.cardSubtitle}>
            {splitSubtitle && subtitle
              ? subtitle
                  .split("\n")
                  .map((str, index) => <p key={index}>{str}</p>)
              : subtitle}
          </span>
          <strong className={styles.cardDescription}>{description}</strong>
          <div className={styles.cardRadioButton}>
            {showRadioButton && <FormFieldRadioButton selected={selected} />}
          </div>
        </div>
      </div>
    );
  }

  function OrganizationCard() {
    return (
      <div
        onClick={() => {
          history.push(url);
        }}
        className={`card ${styles.cardPerson}`}
        {...otherProps}
      >
        {showDropdown && dropdown}
        <div className={`card-image ${styles.cardImage}`}>
          <figure className="image">
            {imageUrl ? (
              <img src={imageUrl} alt={title} className="p-3" />
            ) : (
              <CircleIcon icon="work_outline" size="xxlarge" />
            )}
          </figure>
        </div>
        <div className={styles.status}></div>
        <footer className={`card-footer ${styles.cardFooter}`}>
          <span className={styles.cardTitle}>{title}</span>
          <span className={styles.cardSubtitle}>{subtitle}</span>
        </footer>
      </div>
    );
  }

  if (type === "simple") return <SimpleCard />;
  if (type === "organization") return <OrganizationCard />;
  return <DocumentCard />;
}

Card.propTypes = {
  /**
   * The main title of your card.  Typically a Document name, Folder name or Person name.
   */
  title: PropTypes.string.isRequired,
  /**
   * The subtitle of your card.
   */
  subtitle: PropTypes.string,
  /**
   * The type of card you wish to display.
   */
  type: PropTypes.oneOf([
    "document",
    "folder",
    "person",
    "place",
    "organization",
    "placeholder",
    "simple",
    "marketing",
    "help",
  ]),
  /**
   * The main image displayed on the card.
   */
  imageUrl: PropTypes.string,
};
