import { AssetTypeEnum } from "enums/assetType";

const documentSortOptions = [
  {
    id: "docName",
    title: "Document Name",
  },
  {
    id: "recentlyUpdated",
    title: "Recently Updated",
  },
  {
    id: "dateAdded",
    title: "Date Added",
  },
  {
    id: "authorName",
    title: "Author Name",
  },
];

const peopleLayoutOptions = [
  {
    id: "grid",
    title: "Grid View",
  },
  {
    id: "list",
    title: "List View",
  },
];

const peopleSortOptions = [
  {
    id: "firstName",
    title: "First Name",
  },
  {
    id: "lastName",
    title: "Last Name",
  },
  {
    id: "dateAdded",
    title: "Date Added",
  },
  {
    id: "relationship",
    title: "Relationship",
  },
];

const peopleRelationshipOptions = [
  {
    id: "all",
    title: "All",
  },
  {
    id: "coowner",
    title: "Co-Owner",
  },
  {
    id: "keyholder",
    title: "Keyholder",
  },
  {
    id: "spouse",
    title: "Spouse",
  },
  {
    id: "mother",
    title: "Mother",
  },
  {
    id: "father",
    title: "Father",
  },
  {
    id: "daughter",
    title: "Daughter",
  },
  {
    id: "son",
    title: "Son",
  },
  {
    id: "otherfamily",
    title: "Other Family",
  },
  {
    id: "advisor",
    title: "Advisor",
  },
];

const placesLayoutOptions = [
  {
    id: "grid",
    title: "Grid View",
  },
  {
    id: "list",
    title: "List View",
  },
  {
    id: "map",
    title: "Map View",
  },
];

const placesSortOptions = [
  {
    id: "name",
    title: "Name",
  },
  {
    id: "location",
    title: "Location",
  },
  {
    id: "type",
    title: "Type",
  },
];

const placesFilterOptions = [
  {
    id: "all",
    title: "All",
  },
  {
    id: "physicalLocation",
    title: "Physical Location",
  },
  {
    id: "digital",
    title: "Digital",
  },
];

export function sortDocuments(documents) {
  let sortFunction = (a, b) =>
    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
  return documents && documents.sort(sortFunction);
}

export function sortPeople(people) {
  let sortFunction = (a, b) =>
    a.lastName.toLowerCase() + a.firstName.toLowerCase() >
    b.lastName.toLowerCase() + b.firstName.toLowerCase()
      ? 1
      : -1;
  return people && people.sort(sortFunction);
}

export function sortPlaces(places) {
  let sortFunction = (a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  return places && places.sort(sortFunction);
}

export function sortThings(things) {
  let sortFunction = (a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  return things && things.sort(sortFunction);
}

export function sortAssets(assets, sortBy = "name", direction = "asc") {
  let sortFunction = (a, b) =>
    a[sortBy].toLowerCase() > b[sortBy].toLowerCase()
      ? direction === "asc"
        ? 1
        : -1
      : direction === "asc"
      ? -1
      : 1;
  return assets && assets.sort(sortFunction);
}

export function sortMedia(media, sortBy = "createdAt", direction = "desc") {
  let sortFunction = (a, b) =>
    a[sortBy].toLowerCase() > b[sortBy].toLowerCase()
      ? direction === "asc"
        ? 1
        : -1
      : direction === "desc"
      ? -1
      : 1;
  return media && media.sort(sortFunction);
}

export function sortRelatedItems(items) {
  if (!items || items.length === 0) return [];
  const documents = items.filter(
    (l) => l.asset.assetType === AssetTypeEnum.DOCUMENT
  );
  const people = items.filter(
    (l) => l.asset.assetType === AssetTypeEnum.PERSON.toLowerCase()
  );
  const places = items.filter(
    (l) => l.asset.assetType === AssetTypeEnum.PLACE.toLowerCase()
  );
  const things = items.filter(
    (l) => l.asset.assetType === AssetTypeEnum.THING.toLowerCase()
  );

  documents.sort((a, b) => (a.asset.name > b.asset.name ? 1 : -1));
  people.sort((a, b) => (a.asset.name > b.asset.name ? 1 : -1));
  places.sort((a, b) => (a.asset.name > b.asset.name ? 1 : -1));
  things.sort((a, b) => (a.asset.name > b.asset.name ? 1 : -1));

  return [...documents, ...people, ...places, ...things];
}

export function sortOrganizations(organizations) {
  if (!organizations) return;
  let sortFunction = (a, b) =>
    a?.displayName?.toLowerCase() > b?.displayName?.toLowerCase() ? 1 : -1;
  return organizations && organizations?.sort(sortFunction);
}

export function sortMembers(members) {
  let sortFunction = (a, b) =>
    a.emailId.toLowerCase() + a.emailId.toLowerCase() >
    b.emailId.toLowerCase() + b.emailId.toLowerCase()
      ? 1
      : -1;
  return members ? members.sort(sortFunction) : [];
}
