import React, { useState, useRef } from "react";
import { Link, NavLink } from "util/router.js";
import Logo from "svgs/logo/Logo";
import AccountDropdown from "./AccountDropdown";
import "./Navbar.scss";
import styles from "./Navbar.module.scss";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";

function Navbar({ setSearch }) {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
    }
  };

  return (
    <>
      <nav className={cx("navbar", styles.navbar)}>
        <div className={cx("container", styles.container)}>
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link
                to={`/dashboard`}
                className="navbar-link is-arrowless"
                id="navbar-logo-btn"
              >
                <Logo className="logo" alt="Logo" showText />
                <h3 className={styles.siteName}>Admin Console</h3>
              </Link>
            </div>
            <div
              className={
                "navbar-burger burger" + (menuOpen ? " is-active" : "")
              }
              onClick={() => setMenuOpen(!menuOpen)}
              id="navbar-mobile-menu-btn"
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
            <div className={cx("navbar-start", styles.navbarMiddle)}>
              <NavLink
                className="navbar-item"
                to={`/dashboard`}
                activeClassName="is-active"
                id="navbar-dashboard-btn"
                onClick={() => {
                  menuOpen && setMenuOpen(!menuOpen);
                }}
              >
                Dashboard
              </NavLink>
              <NavLink
                className="navbar-item"
                to={`/organizations`}
                activeClassName="is-active"
                id="navbar-organizations-btn"
                onClick={() => {
                  menuOpen && setMenuOpen(!menuOpen);
                }}
              >
                Organizations
              </NavLink>
            </div>
            <div className="navbar-end">
              <AccountDropdown />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
